import React from "react";
import { Tooltip } from "~zipdeal-ui/components/Tooltip.tsx";
import { CircledQuestionMarkIcon } from "~zipdeal-ui/icons/ButtonIcons.tsx";
import { Spinner } from "~zipdeal-ui/spinners/Spinners.tsx";

export const ZipTip = ({
	zipTip,
	hasZipTip,
	loadZipTip,
}: {
	zipTip?: string | null;
	hasZipTip?: boolean;
	loadZipTip?: () => void;
}) => {
	if (!hasZipTip) return null;

	return (
		<Tooltip
			tooltipOffset={[0, 15]}
			tooltipPosition={"right"}
			tooltipDelay={"short"}
			tooltipWrapperClassName={"z-201"}
			tooltipClassName={"text-gray-900 border-2 rounded-md bg-white shadow-sm px-2 py-2"}
			tooltip={
				zipTip ? (
					<div
						className={"max-w-64 break-normal"}
						dangerouslySetInnerHTML={{ __html: zipTip }}
					/>
				) : (
					<div className="relative mx-auto h-24 w-24">
						<Spinner />
					</div>
				)
			}
		>
			<div
				className={"h-6 w-6 cursor-pointer items-center text-gray-400 hover:text-gray-700"}
				onMouseEnter={() => loadZipTip?.()}
			>
				<CircledQuestionMarkIcon />
			</div>
		</Tooltip>
	);
};
