import { create } from "zustand";

interface ZipTipsStoreState {
	zipTips: { id?: string; field?: string; tip?: string }[];
	setZipTips: (i: any) => void;
}

export const createZipTipsStore = () => {
	return create<ZipTipsStoreState>()((set) => ({
		zipTips: [],
		setZipTips: (i) => set((state) => ({ zipTips: i })),
	}));
};

export const useZipTipStore = createZipTipsStore();
